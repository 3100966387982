<template>
	<div style="overflow: hidden;" :style="{height:winHeight+'px'}">
		<img src="../assets/image/poster/poster.png" style="width: 100%;height: 850px;">
	</div>
</template>

<script>
	export default {
		name: "start",
		data() {
			return {
				winHeight: this.initial.getWH().Height
			}
		},
		components: {

		},
		created() {
			setTimeout(()=>{
				this.$router.push({
					path:'/home'
				})
			},3000)
		},
		methods: {

		}
	}
</script>

<style scoped>
</style>
